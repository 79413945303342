<template>
  <section title-accordion v-editable="blok">
    <div class="container">
      <div class="row">
        <div class="section-content">
          <div v-if="blok.header" v-html="blok.header" class="section-header"></div>
          <h2 v-if="blok?.title && title != ''" v-html="title" class="section-title"></h2>
          <div v-if="blok?.description && description != ''" v-html="description" class="section-description"></div>
        </div>
        <div class="section-accordion">
          <div class="accordion-card">
            <AccordionItem v-for="(item, i) in blok.accordion" :key="item._uid" :blok="item" :open="active === i" v-model="active" :value="i" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/title-accordion.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });

const title = computed(() => renderRichText(props?.blok?.title || {}));
const description = computed(() => renderRichText(props?.blok?.description || {}));

const active = ref(0);

</script>
